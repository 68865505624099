// import { Permission } from "@ansiblelabsxyz/beam-common/prisma";
import { CurrentUser } from "../contexts/Auth";

export const isSuperAdmin = (currentUser: CurrentUser) => {
  if (!currentUser) {
    return false;
  }
  return currentUser.permissions.some(
    // (permission) => permission === Permission.SuperAdmin
    (permission) => permission === "SuperAdmin",
  );
};
