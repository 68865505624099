import Dialog from "./Dialog";
import {
  Square2StackIcon,
  EyeIcon,
  EyeSlashIcon,
  ArrowLeftIcon,
  BanknotesIcon,
} from "@heroicons/react/24/outline";

import { useToast } from "../contexts/ToastContext";
import { copy } from "../utils/copy";
import { useEffect, useState } from "react";
import { formatNumberToCurrency } from "../utils";
import API from "../api/api";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import { amountRegex } from "../utils/regex";

const tabs = [
  { name: "Deposit", current: true },
  { name: "Withdrawal", current: false },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const FiatDepositInstructions = ({
  open,
  setOpen,
  instructions,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  instructions: any;
}) => {
  const { showToast } = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(tabs.find((tab) => tab.current));
  const [isVisible, setIsVisible] = useState(false);
  const [accounts, setBankAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState<null | any>(null);
  const [withdrawalAmount, setWithdrawalAmount] = useState<string>("0");
  const [memo, setMemo] = useState<string>("");
  const withdrawalAmountNumber = Number(withdrawalAmount);
  const { disbursable, accountId } = instructions;
  const { wire } = instructions?.instructions || {};
  const [previewSend, setPreviewSend] = useState(false);
  const [estimatedTransactionDetails, setEstimatedTransactionDetails] =
    useState<{ effectiveDate: string; fees: number } | null>(null);

  const {
    accountNumber,
    routingNumber,
    receiverBankName,
    receiverBankAddress,
    receiverName,
    receiverAddress,
  } = wire;

  const validateAndSetWithdrawalAmount = (amount: string) => {
    // delete non-numeric chars
    amount = amount.replace(/[^0-9.]/g, "");

    if (amountRegex.test(amount)) {
      setWithdrawalAmount(amount);
    }
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const maskedAccountNumber = isVisible
    ? accountNumber
    : "•".repeat(accountNumber?.length - 4) + accountNumber?.slice(-4);

  const copyToClipboard = async (title: string, text: string) => {
    const success = await copy(text);
    if (success) {
      showToast(title, "", <Square2StackIcon height={20} />);
    }
  };

  const getAccountDisplayName = (accountId: any) => {
    const account = accounts?.find((account) => account?.id === accountId);
    return `Account ${account?.last4} (${
      account.type === "financial" ? "ACH" : account.type
    }) - ${account?.description || account?.bankName}`;
  };

  const getAccountPaymentRail = (accountId: any) => {
    const account = accounts?.find((account) => account?.id === accountId);
    switch (account?.type) {
      case "financial":
        return "Next Day ACH";
      case "wire":
        return "Same Day Wire";
      default:
        return "";
    }
  };

  const disableSubmit = () => {
    if (
      !selectedAccount ||
      withdrawalAmountNumber === 0 ||
      disbursable < withdrawalAmountNumber
    ) {
      return true;
    }
    return false;
  };

  const submitTransfer = async () => {
    setLoading(true);
    // business/:id/transaction
    await API.post(`business/${accountId}/transaction`, {
      paymentAccountId: selectedAccount,
      accountId,
      amount: withdrawalAmountNumber,
      memo,
    });
    setLoading(false);
    showToast(
      "Transfer successful",
      "",
      <BanknotesIcon height={20} color="green" />,
    );
    // toast success
    // close dialog
    setOpen(false);
    // use selected account and amount
    // make a post request to the api
  };

  useEffect(() => {
    const getBankAccounts = async () => {
      const { data } = await API.get(`business/bankAccounts/${accountId}`);
      setBankAccounts(data);
    };
    getBankAccounts();

    return () => {
      setBankAccounts([]);
    };
  }, [accountId]);

  // get the accounts from the user

  useEffect(() => {
    const getEstimateTransactionDetails = async () => {
      const { data } = await API.post(`business/estimateTransactionDetails`, {
        accountId,
        amount: withdrawalAmountNumber,
        paymentAccountId: selectedAccount,
      });
      if (data) {
        setEstimatedTransactionDetails(data);
      }
    };

    if (selectedAccount && withdrawalAmountNumber > 0) {
      getEstimateTransactionDetails();
    }
  }, [selectedAccount, withdrawalAmountNumber, accountId]);

  const formatedWithdrawalAmount = withdrawalAmountNumber.toLocaleString();

  return (
    <Dialog open={open} setOpen={setOpen}>
      <div>
        <button
          className="p-2 absolute right-3 top-3" // Button styling
          onClick={() => {
            setOpen(false);
          }}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setOpen(false)}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
        {!previewSend && (
          <>
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-lavender-500 focus:outline-none focus:ring-lavender-500 sm:text-sm"
                defaultValue={tabs.find((tab) => tab.current).name}
              >
                {tabs.map((tab) => (
                  <option key={tab.name} onClick={(e) => setCurrentTab(tab)}>
                    {tab.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block mt-4">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <span
                      key={tab.name}
                      onClick={(e) => setCurrentTab(tab)}
                      className={classNames(
                        tab.name === currentTab?.name
                          ? "border-lavender-500 text-lavender-800"
                          : "border-transparent text-gray-500 hover:border-lavender-300 hover:text-lavender-700 cursor-pointer",
                        "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium",
                      )}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      {tab.name}
                    </span>
                  ))}
                </nav>
              </div>
            </div>
            {currentTab?.name === "Deposit" && (
              <>
                <p className="mt-2 text-sm text-gray-500 text-center sm:text-left">
                  The following account can be used for ACH and Wire
                </p>
                <div className="flex flex-col justify-center items-center mt-4 space-y-2">
                  <div className="flex w-full items-center justify-between py-4">
                    <div className="flex-1">
                      <div className="mb-1 text-md font-semibold">
                        Account Number{" "}
                        <button
                          onClick={toggleVisibility}
                          className="hover:bg-gray-200 rounded"
                          aria-label={
                            isVisible
                              ? "Hide account number"
                              : "Show full account number"
                          }
                        >
                          {isVisible ? (
                            <EyeIcon className="h-4 w-4" />
                          ) : (
                            <EyeSlashIcon className="h-4 w-4" />
                          )}
                        </button>
                      </div>
                      <div className="text-sm text-gray-600">
                        {maskedAccountNumber}
                      </div>
                    </div>

                    <button
                      className="flex items-center justify-center px-2 py-1 border-2 border-lavender-500 text-lavender-500 rounded-md hover:bg-lavender-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-lavender-800 focus:ring-opacity-50 transition-colors duration-200"
                      onClick={() =>
                        copyToClipboard(
                          "Account number copied to clipboard",
                          accountNumber,
                        )
                      }
                    >
                      <Square2StackIcon height={20} />
                      <span className="ml-4">Copy</span>
                    </button>
                  </div>
                  <div className="flex w-full items-center justify-between py-4">
                    <div className="flex-1">
                      <div className="mb-1 text-md font-semibold">
                        Routing Number
                      </div>
                      <div className="text-sm text-gray-600">
                        {routingNumber}
                      </div>
                    </div>

                    <button
                      className="flex items-center justify-center px-2 py-1 border-2 border-lavender-500 text-lavender-500 rounded-md hover:bg-lavender-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-lavender-800 focus:ring-opacity-50 transition-colors duration-200"
                      onClick={() =>
                        copyToClipboard(
                          "Routing number copied to clipboard",
                          routingNumber,
                        )
                      }
                    >
                      <Square2StackIcon height={20} />
                      <span className="ml-4">Copy</span>
                    </button>
                  </div>
                  <div className="flex flex-col items-center justify-center mt-6 w-full">
                    <div className="flex justify-between items-start w-full py-4">
                      <div className="text-md font-semibold mb-2">
                        Account Holder
                      </div>
                      <address className="not-italic text-right">
                        {receiverName && (
                          <p className="text-gray-600">{receiverName}</p>
                        )}
                        {receiverAddress?.street1 && (
                          <p className="text-gray-600">
                            {receiverAddress?.street1}
                          </p>
                        )}
                        {receiverAddress?.street2 && (
                          <p className="text-gray-600">
                            {receiverAddress?.street2}
                          </p>
                        )}
                        {receiverAddress?.city && receiverAddress?.state && (
                          <p className="text-gray-600">{`${receiverAddress?.city}, ${receiverAddress?.state} ${receiverAddress?.postalCode}`}</p>
                        )}
                      </address>
                    </div>
                    <div className="flex justify-between items-start w-full mt-2">
                      <div className="text-md font-semibold mb-2">
                        Bank Details
                      </div>
                      <address className="not-italic text-right">
                        {receiverBankName && (
                          <p className="text-gray-600">{receiverBankName}</p>
                        )}
                        {receiverBankAddress?.street1 && (
                          <p className="text-gray-600">
                            {receiverBankAddress?.street1}
                          </p>
                        )}
                        {receiverBankAddress?.street2 && (
                          <p className="text-gray-600">
                            {receiverBankAddress?.street2}
                          </p>
                        )}
                        {receiverBankAddress?.city &&
                          receiverBankAddress?.state && (
                            <p className="text-gray-600">{`${receiverBankAddress?.city}, ${receiverBankAddress?.state} ${receiverBankAddress?.postalCode}`}</p>
                          )}
                      </address>
                    </div>
                  </div>
                </div>
              </>
            )}
            {currentTab?.name === "Withdrawal" && (
              <div className="flex flex-col justify-center items-center mt-4 space-y-2">
                <div className="flex w-full items-center justify-between py-4">
                  <div className="flex flex-col mt-2 w-full">
                    <label
                      htmlFor="bank-address-city"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Amount
                    </label>
                    <div className="mt-2">
                      <div className="relative rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <span className="text-gray-500 sm:text-sm">$</span>
                        </div>
                        <input
                          type="text"
                          name="price"
                          id="price"
                          value={withdrawalAmount}
                          onChange={(e) =>
                            validateAndSetWithdrawalAmount(e.target.value)
                          }
                          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lavender-600 sm:text-sm sm:leading-6"
                          placeholder="0.00"
                          aria-describedby="price-currency"
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                          <button
                            onClick={() =>
                              validateAndSetWithdrawalAmount(
                                String(disbursable),
                              )
                            }
                            className="hover:bg-gray-200 rounded cursor-pointer"
                            aria-label={"Set max available"}
                          >
                            <span
                              className="text-gray-500 sm:text-sm"
                              id="set-max-available"
                            >
                              MAX
                            </span>
                          </button>
                        </div>
                      </div>
                      <p
                        className={`mt-2 text-sm ${
                          withdrawalAmountNumber <= disbursable
                            ? "text-gray-500"
                            : "text-red-500"
                        }`}
                        id="email-description"
                      >
                        {withdrawalAmountNumber <= disbursable
                          ? `$${formatNumberToCurrency(disbursable)} available`
                          : `Insufficient funds $${formatNumberToCurrency(
                              disbursable,
                            )} available`}
                      </p>
                    </div>
                    <div className="mt-4">
                      <label
                        htmlFor="account"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Destination Account
                      </label>
                      <select
                        id="account"
                        name="account"
                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-lavender-600 sm:text-sm sm:leading-6"
                        defaultValue=""
                        value={selectedAccount}
                        onChange={(e) => {
                          if (e.target.value === "add") {
                            navigate("/connected-accounts#add-bank-account");
                          }
                          setMemo("");
                          setSelectedAccount(e.target.value);
                        }}
                      >
                        <option value="">Select an account</option>
                        {accounts?.map((account, idx) => (
                          <option
                            value={account.id}
                            key={`${idx}-${account.id}`}
                          >
                            {getAccountDisplayName(account.id)}
                          </option>
                        ))}
                        <option value="add">Add a payment method</option>
                      </select>
                    </div>
                    {accounts?.find((account) => account.id === selectedAccount)
                      ?.type === "wire" && (
                      <>
                        <div className="mt-4">
                          <label
                            htmlFor="account"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Memo
                          </label>
                          <input
                            type="text"
                            name="memo"
                            id="memo"
                            value={memo}
                            onChange={(e) => setMemo(e.target.value)}
                            maxLength={140}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lavender-600 sm:text-sm sm:leading-6"
                            placeholder="Invoice 123"
                            aria-describedby="wire-memo"
                          />
                        </div>
                      </>
                    )}
                    <div className="mt-12 w-full">
                      <Button
                        text="Review"
                        loading={false}
                        className="w-full justify-center"
                        onSubmit={() => {
                          setPreviewSend(true);
                        }}
                        disabled={disableSubmit()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {previewSend && (
          <div className="flex flex-col align-start">
            <div className="flex justify-center items-center w-full">
              <button
                onClick={() => setPreviewSend(false)}
                className="flex items-center px-2 py-1 text-gray-500 hover:text-gray-700 absolute left-5 top-5"
              >
                <ArrowLeftIcon className="h-5 w-5 mr-1" />
              </button>
              <span className="text-2xl">
                Transfer ${formatedWithdrawalAmount} USD
              </span>
            </div>

            <div className="mt-4 flex flex-row justify-between">
              <span>Transfer To</span>
              <span>{getAccountDisplayName(selectedAccount)}</span>
            </div>

            <div className="mt-4 flex flex-row justify-between">
              <span>Payment Rail</span>
              <span>{getAccountPaymentRail(selectedAccount)}</span>
            </div>
            {memo && (
              <div className="mt-4 flex flex-row justify-between">
                <span>Memo</span>
                <span>{memo}</span>
              </div>
            )}

            <div className="mt-4 flex flex-row justify-between">
              <span>Estimated Delivery</span>
              <span>{estimatedTransactionDetails?.effectiveDate}</span>
            </div>

            <div className="mt-4 flex flex-row justify-between">
              <span>Payment Fees</span>
              <span>${estimatedTransactionDetails?.fees}</span>
            </div>

            <Button
              text="Send Now"
              loading={loading}
              className="w-full justify-center mt-4"
              onSubmit={() => {
                submitTransfer();
              }}
            />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default FiatDepositInstructions;
