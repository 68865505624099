import Dialog from "./Dialog";
import QRCode from "./QRCode";
import { Square2StackIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

import { useToast } from "../contexts/ToastContext";
import { copy } from "../utils/copy";
import { useEffect, useState } from "react";
import API from "../api/api";
import { amountRegex } from "../utils/regex";
import { formatNumberToCurrency } from "../utils";
import Button from "./Button";
import { useNavigate } from "react-router-dom";

const tabs = [
  { name: "Deposit", current: true },
  { name: "Withdrawal", current: false },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const CryptoDepositInstructions = ({
  open,
  setOpen,
  instructions,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  instructions: any;
}) => {
  console.log(instructions);
  const { showToast } = useToast();
  const { disbursable, accountId } = instructions;
  const [currentTab, setCurrentTab] = useState(tabs.find((tab) => tab.current));
  const [wallets, setWallets] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState("");
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const [withdrawalAmountNumber, setWithdrawalAmountNumber] = useState(0);
  const [previewSend, setPreviewSend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmSubmission, setConfirmSubmission] = useState(false);
  const navigate = useNavigate();

  const copyToClipboard = async (text: string) => {
    const success = await copy(text);
    if (success) {
      showToast(
        "Address copied to clipboard",
        "",
        <Square2StackIcon height={20} />,
      );
    }
  };

  const validateAndSetWithdrawalAmount = (amount: string) => {
    // delete non-numeric chars
    amount = amount.replace(/[^0-9.]/g, "");

    if (amountRegex.test(amount)) {
      setWithdrawalAmount(amount);
      setWithdrawalAmountNumber(Number(amount));
    }
  };

  const submitTransfer = async () => {
    setLoading(true);
    try {
      await API.post(`business/${accountId}/cryptoTransaction`, {
        source: {
          walletId: instructions?.id,
        },
        destination: {
          externalAccountId: selectedWallet,
        },
        amount: withdrawalAmountNumber,
        asset: instructions?.asset?.symbol,
        network: instructions?.asset?.network?.fortressNetworkId,
      });
      showToast(
        "Transfer successful",
        "",
        <Square2StackIcon height={20} color="green" />,
      );
      setOpen(false);
    } catch (error) {
      console.error(error);
      showToast(
        "Transfer failed",
        "",
        <Square2StackIcon height={20} color="red" />,
      );
    }
    setLoading(false);
  };

  const disableSubmit = () => {
    return (
      !selectedWallet ||
      withdrawalAmountNumber === 0 ||
      disbursable < withdrawalAmountNumber
    );
  };

  useEffect(() => {
    const getWallets = async () => {
      const { data } = await API.get(`business/${accountId}/externalWallets`);
      setWallets(data);
    };
    getWallets();

    return () => {
      setWallets([]);
    };
  }, [accountId]);

  return (
    <Dialog open={open} setOpen={setOpen}>
      <div>
        <button
          className="p-2 absolute right-3 top-3"
          onClick={() => {
            setOpen(false);
          }}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setOpen(false)}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>

        {!previewSend && (
          <>
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-lavender-500 focus:outline-none focus:ring-lavender-500 sm:text-sm"
                defaultValue={tabs.find((tab) => tab.current).name}
              >
                {tabs.map((tab) => (
                  <option key={tab.name} onClick={(e) => setCurrentTab(tab)}>
                    {tab.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block mt-4">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <span
                      key={tab.name}
                      onClick={(e) => setCurrentTab(tab)}
                      className={classNames(
                        tab.name === currentTab?.name
                          ? "border-lavender-500 text-lavender-800"
                          : "border-transparent text-gray-500 hover:border-lavender-300 hover:text-lavender-700 cursor-pointer",
                        "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium",
                      )}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      {tab.name}
                    </span>
                  ))}
                </nav>
              </div>
            </div>

            {currentTab?.name === "Deposit" && (
              <div className="mt-3 sm:mt-5 mb-2">
                <div className="flex justify-between items-center">
                  <div className="flex-grow text-center">
                    <h4 className="text-2xl leading-8 text-gray-900">
                      {instructions?.asset?.symbol} on{" "}
                      {instructions?.asset?.network?.name}
                    </h4>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <div className="my-8">
                    <QRCode text={instructions?.address} />
                  </div>
                  <p className="text-sm break-all">{instructions?.address}</p>

                  <button
                    className="w-full py-2 border-2 border-gray-400 rounded-md flex justify-center items-center space-x-2 hover:border-gray-600 mt-8"
                    onClick={() => copyToClipboard(instructions?.address)}
                  >
                    <Square2StackIcon height={20} />
                    <span>Copy</span>
                  </button>
                </div>
              </div>
            )}

            {currentTab?.name === "Withdrawal" && (
              <div className="mt-3 sm:mt-5 mb-4">
                <div className="flex flex-col items-center justify-center">
                  <div className="flex flex-col mt-2 w-full">
                    <label
                      htmlFor="bank-address-city"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Amount
                    </label>
                    <div className="mt-2">
                      <div className="relative rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <span className="text-gray-500 sm:text-sm">$</span>
                        </div>
                        <input
                          type="text"
                          name="price"
                          id="price"
                          value={withdrawalAmount}
                          onChange={(e) =>
                            validateAndSetWithdrawalAmount(e.target.value)
                          }
                          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lavender-600 sm:text-sm sm:leading-6"
                          placeholder="0.00"
                          aria-describedby="price-currency"
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                          <button
                            onClick={() =>
                              validateAndSetWithdrawalAmount(
                                String(disbursable),
                              )
                            }
                            className="hover:bg-gray-200 rounded cursor-pointer"
                            aria-label={"Set max available"}
                          >
                            <span
                              className="text-gray-500 sm:text-sm"
                              id="set-max-available"
                            >
                              MAX
                            </span>
                          </button>
                        </div>
                      </div>
                      <p
                        className={`mt-2 text-sm ${
                          withdrawalAmountNumber <= disbursable
                            ? "text-gray-500"
                            : "text-red-500"
                        }`}
                        id="email-description"
                      >
                        {withdrawalAmountNumber <= disbursable
                          ? `${disbursable} available`
                          : `Insufficient funds $${formatNumberToCurrency(
                              disbursable,
                            )} available`}
                      </p>
                    </div>
                  </div>
                  <div className="mt-4 w-full">
                    <label
                      htmlFor="wallet"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Destination Wallet
                    </label>
                    <select
                      id="wallet"
                      name="wallet"
                      className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-lavender-600 sm:text-sm sm:leading-6"
                      defaultValue=""
                      value={selectedWallet}
                      onChange={(e) => {
                        if (e.target.value === "add") {
                          navigate("/connected-accounts#add-wallet");
                        }
                        setSelectedWallet(e.target.value);
                      }}
                    >
                      <option value="">Select a wallet</option>
                      {wallets?.map((wallet, idx) => (
                        <option value={wallet.id} key={`${idx}-${wallet.id}`}>
                          {wallet.description} ({wallet.address})
                        </option>
                      ))}
                      <option value="add">Add a wallet</option>
                    </select>
                  </div>
                  <div className="mt-12 w-full">
                    <Button
                      text="Review"
                      loading={false}
                      className="w-full justify-center"
                      onSubmit={() => {
                        setPreviewSend(true);
                      }}
                      disabled={disableSubmit()}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {previewSend && (
          <div className="flex flex-col align-start">
            <div className="flex justify-center items-center w-full">
              <button
                onClick={() => setPreviewSend(false)}
                className="flex items-center px-2 py-1 text-gray-500 hover:text-gray-700 absolute left-5 top-5"
              >
                <ArrowLeftIcon className="h-5 w-5 mr-1" />
              </button>
              <span className="text-2xl">
                Transfer {withdrawalAmountNumber.toLocaleString()}{" "}
                {instructions?.asset?.symbol}
              </span>
            </div>

            <div className="mt-4 flex flex-col justify-between">
              <span>Destination</span>
              <span className="text-gray-500 pl-2">
                {wallets.find((w) => w.id === selectedWallet)?.description} (
                {wallets.find((w) => w.id === selectedWallet)?.address})
              </span>
            </div>

            <div className="mt-4 flex flex-col justify-between">
              <span>Network</span>
              <span className="text-gray-500 pl-2">
                {instructions?.asset?.network?.name}
              </span>
            </div>

            <div className="mt-4 flex flex-col justify-between">
              <div className="flex items-center mt-2">
                <input
                  id="confirm-checkbox"
                  type="checkbox"
                  checked={confirmSubmission}
                  onChange={(e) => setConfirmSubmission(e.target.checked)}
                  className="h-4 w-4 rounded border-gray-300 text-lavender-600 focus:ring-lavender-600"
                />
                <label
                  htmlFor="confirm-checkbox"
                  className="ml-2 text-sm text-gray-500"
                >
                  I understand that sending funds to an unsupported wallet may
                  result in permanent loss of funds
                </label>
              </div>
            </div>

            <Button
              text="Send Now"
              loading={loading}
              disabled={!confirmSubmission || loading}
              className="w-full justify-center mt-8"
              onSubmit={submitTransfer}
            />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default CryptoDepositInstructions;
